function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import { AUTH_DELETE_TOKEN, AUTH_SET_LAST_REQUESTED_ROUTE, AUTH_SIGN_IN } from "@common/constants/action-types";
import request from "@common/services/request";
import { MOST_WATCHED, REDIRECT_DEFAULT_ROUTE, ROUTE_LOGOUT, ROUTE_NO_MATCH } from "@config/constants";
export var setLastRequestedRoute = function(path) {
    if (path === ROUTE_NO_MATCH) {
        path = MOST_WATCHED;
    }
    sessionStorage.setItem("lastRoute", path);
    return function(dispatch) {
        dispatch({
            type: AUTH_SET_LAST_REQUESTED_ROUTE,
            payload: path
        });
    };
};
export var signIn = function(credentials, googleTokenObj) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var _ref, tokenObj, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            4,
                            ,
                            5
                        ]);
                        if (!(googleTokenObj === undefined)) return [
                            3,
                            2
                        ];
                        return [
                            4,
                            request({
                                method: "post",
                                url: "/users/login",
                                data: credentials
                            })
                        ];
                    case 1:
                        _ref = _state.sent(), tokenObj = _ref.data;
                        dispatch({
                            type: AUTH_SIGN_IN,
                            payload: tokenObj
                        });
                        return [
                            2,
                            Promise.resolve(tokenObj)
                        ];
                    case 2:
                        dispatch({
                            type: AUTH_SIGN_IN,
                            payload: googleTokenObj
                        });
                        return [
                            2,
                            Promise.resolve(googleTokenObj)
                        ];
                    case 3:
                        return [
                            3,
                            5
                        ];
                    case 4:
                        error = _state.sent();
                        // Send the error to the component
                        // that has triggered the action.
                        // This gives the opportunity to
                        // display specific server errors
                        // in components without using
                        // the redux store.
                        return [
                            2,
                            Promise.reject(error)
                        ];
                    case 5:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var signUp = function(signUpData) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var response, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            request({
                                method: "post",
                                url: "/users",
                                data: signUpData
                            })
                        ];
                    case 1:
                        response = _state.sent();
                        // Allow the invoking component to
                        // know when the request has succeeded
                        // and render the post registration
                        // info.
                        return [
                            2,
                            Promise.resolve(response)
                        ];
                    case 2:
                        error = _state.sent();
                        // Send the error to the component
                        // that has triggered the action.
                        // This gives the opportunity to
                        // display specific server errors
                        // in components without using
                        // the redux store.
                        return [
                            2,
                            Promise.reject(error)
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var testGeoRestriction = function() {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var response, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            request({
                                method: "get",
                                url: "/geo"
                            })
                        ];
                    case 1:
                        response = _state.sent();
                        // Allow the invoking component to
                        // know when the request has succeeded
                        // and render the post registration
                        // info.
                        return [
                            2,
                            Promise.resolve(response)
                        ];
                    case 2:
                        error = _state.sent();
                        // Send the error to the component
                        // that has triggered the action.
                        // This gives the opportunity to
                        // display specific server errors
                        // in components without using
                        // the redux store.
                        return [
                            2,
                            Promise.reject(error)
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var requestChangePasswordLink = function(email) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var trimmedEmail, response, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        trimmedEmail = email.replace(/\s/g, "");
                        return [
                            4,
                            request({
                                method: "get",
                                url: "users/resetpassword?email=".concat(trimmedEmail)
                            })
                        ];
                    case 1:
                        response = _state.sent();
                        // Allow the invoking component to
                        // know when the request has succeeded
                        // and render the appropriate info.
                        return [
                            2,
                            Promise.resolve(response)
                        ];
                    case 2:
                        error = _state.sent();
                        // Send the error to the component
                        // that has triggered the action.
                        // This gives the opportunity to
                        // display specific server errors
                        // in components without using
                        // the redux store.
                        return [
                            2,
                            Promise.reject(error)
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var resetPassword = function(data) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var response, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            request({
                                method: "post",
                                url: "users/resetpassword",
                                data: data
                            })
                        ];
                    case 1:
                        response = _state.sent();
                        // Allow the invoking component to
                        // know when the request has succeeded
                        // and render the appropriate info.
                        return [
                            2,
                            Promise.resolve(response)
                        ];
                    case 2:
                        error = _state.sent();
                        // Send the error to the component
                        // that has triggered the action.
                        // This gives the opportunity to
                        // display specific server errors
                        // in components without using
                        // the redux store.
                        return [
                            2,
                            Promise.reject(error)
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var changePassword = function(data) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var response;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        return [
                            4,
                            request({
                                method: "post",
                                url: "/users/me/changepassword",
                                data: data
                            })
                        ];
                    case 1:
                        response = _state.sent();
                        // Allow the invoking component to
                        // know when the request has succeeded
                        // and render the appropriate info.
                        return [
                            2,
                            response
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var deleteAuthToken = function() {
    return function(dispatch) {
        dispatch({
            type: AUTH_DELETE_TOKEN,
            payload: {
                redirect: REDIRECT_DEFAULT_ROUTE
            }
        });
        request({
            method: "post",
            url: ROUTE_LOGOUT
        });
    };
};
