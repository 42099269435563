function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import { USER_CONFIRM_DELETION, USER_GET_COUNTRY, USER_GET_DETAILS, USER_GET_FULL_DETAILS, USER_GET_INVOICES, USER_GET_INVOICE_URL, USER_REMOVE_DETAILS } from "@common/constants/action-types";
import request from "@common/services/request";
import { isEmpty } from "lodash";
export var getFullUserDetails = function() {
    var ajax = request({
        method: "get",
        url: "/users/me?details=1"
    });
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var _response_data_subscription, _response_data, response, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            ajax
                        ];
                    case 1:
                        response = _state.sent();
                        // TODO: Why is this needed? Can we not just use the response as is?
                        // The response seems to be in the correct format already.
                        // NOTE: Second bug i've found here, why are we modifying the response data?
                        if (!isEmpty((_response_data = response.data) === null || _response_data === void 0 ? void 0 : (_response_data_subscription = _response_data.subscription) === null || _response_data_subscription === void 0 ? void 0 : _response_data_subscription[0])) {
                            response.data = _object_spread_props(_object_spread({}, response.data), {
                                subscription: [
                                    _object_spread_props(_object_spread({}, response.data.subscription[0]), {
                                        plan: [
                                            _object_spread({
                                                currencyCode: response.data.subscription[0].plan[0].currency
                                            }, response.data.subscription[0].plan[0])
                                        ],
                                        recurringAddons: response.data.subscription[0].addons || []
                                    })
                                ]
                            });
                        }
                        dispatch({
                            type: USER_GET_FULL_DETAILS,
                            payload: response
                        });
                        return [
                            2,
                            response.data
                        ];
                    case 2:
                        error = _state.sent();
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var getUserDetails = function() {
    var ajax = request({
        method: "get",
        url: "/users/me"
    });
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var response, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            ajax
                        ];
                    case 1:
                        response = _state.sent();
                        dispatch({
                            type: USER_GET_DETAILS,
                            payload: response
                        });
                        return [
                            2,
                            response.data
                        ];
                    case 2:
                        error = _state.sent();
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var getInvoiceList = function() {
    var ajax = request({
        method: "get",
        url: "/users/me/invoices"
    });
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var response, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            ajax
                        ];
                    case 1:
                        response = _state.sent();
                        dispatch({
                            type: USER_GET_INVOICES,
                            payload: response
                        });
                        return [
                            2,
                            response.data
                        ];
                    case 2:
                        error = _state.sent();
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var removeUserDetails = function() {
    return function(dispatch) {
        dispatch({
            type: USER_REMOVE_DETAILS,
            payload: {}
        });
    };
};
export var getInvoiceUrl = function(id) {
    var ajax = request({
        method: "get",
        url: "/billing/invoices/".concat(id)
    });
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            ajax
                        ];
                    case 1:
                        data = _state.sent();
                        dispatch({
                            type: USER_GET_INVOICE_URL,
                            payload: data
                        });
                        return [
                            3,
                            3
                        ];
                    case 2:
                        error = _state.sent();
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var deleteAccount = function(param) {
    var password = param.password, token = param.token;
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var response;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        return [
                            4,
                            request({
                                method: "delete",
                                url: "/users/me?access_token=".concat(token),
                                data: {
                                    password: password
                                }
                            })
                        ];
                    case 1:
                        response = _state.sent();
                        // Allow the invoking component to
                        // know when the request has succeeded
                        // and render the appropriate info.
                        return [
                            2,
                            response
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var confirmDeletion = function(shouldConfirm) {
    return function(dispatch) {
        dispatch({
            type: USER_CONFIRM_DELETION,
            payload: shouldConfirm
        });
    };
};
export var cancelSubscriptionRenewal = function(cancellationReason) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var response;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        return [
                            4,
                            request({
                                method: "delete",
                                url: "/users/me/subscription?reason=".concat(encodeURIComponent(cancellationReason))
                            })
                        ];
                    case 1:
                        response = _state.sent();
                        return [
                            2,
                            response
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var editBillingDetails = function(data) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var response, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            request({
                                method: "post",
                                url: "/users/me?access_token=".concat(data.token),
                                data: data
                            })
                        ];
                    case 1:
                        response = _state.sent();
                        return [
                            2,
                            Promise.resolve(response)
                        ];
                    case 2:
                        error = _state.sent();
                        return [
                            2,
                            Promise.reject(error)
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var getUserCountry = function() {
    return function() {
        var _ref = _async_to_generator(function(dispatch, getState) {
            var _response_data, _response_data1, response, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            request({
                                method: "get",
                                url: "/users/me/country"
                            })
                        ];
                    case 1:
                        response = _state.sent();
                        dispatch({
                            type: USER_GET_COUNTRY,
                            payload: response === null || response === void 0 ? void 0 : (_response_data = response.data) === null || _response_data === void 0 ? void 0 : _response_data.country
                        });
                        return [
                            2,
                            response === null || response === void 0 ? void 0 : (_response_data1 = response.data) === null || _response_data1 === void 0 ? void 0 : _response_data1.country
                        ];
                    case 2:
                        error = _state.sent();
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch, getState) {
            return _ref.apply(this, arguments);
        };
    }();
};
