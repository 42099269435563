function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { CURRENT_CHANNEL_SET, CURRENT_CHANNEL_SET_HISTORY_FOR_A_DAY } from "@common/constants/action-types";
export default function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        epgs: {},
        linkIsShown: false,
        channel: {}
    }, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case CURRENT_CHANNEL_SET:
            return _object_spread_props(_object_spread({}, state, action.payload), {
                epgs: {}
            });
        case CURRENT_CHANNEL_SET_HISTORY_FOR_A_DAY:
            return _object_spread_props(_object_spread({}, state), {
                epgs: _object_spread_props(_object_spread({}, state.epgs), _define_property({
                    isTodayEpgsRequestPending: action.payload.isTodayEpgsRequestPending,
                    isTomorrowEpgsRequestPending: action.payload.isTomorrowEpgsRequestPending
                }, action.payload.id, action.payload.epgs))
            });
        default:
            return state;
    }
}
