import { CHANGE_SCREEN_TYPE } from "@common/constants/action-types";
export default function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        web: false,
        tablet: false,
        mobile: false,
        smallMobile: false
    }, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case CHANGE_SCREEN_TYPE:
            return action.payload;
        default:
            return state;
    }
}
