function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import { VIDEO_ALL_EPG, VIDEO_CURRENT, VIDEO_OPTIONS, VIDEO_PLAYER_EPGS, VIDEO_PLAYER_TOGGLE, VIDEO_RESET } from "@common/constants/action-types";
import config from "@common/services/config";
import { getTokenObject } from "@common/services/helpers";
import request from "@common/services/request";
import { API_URL, NOW_EPG, VOD } from "@config/constants";
/**
 * Set video player current url to play
 * @param epg {object} - { isOpen: bool, isPlaying: bool, channelId: string, video: { start: number, end: number } }
 * @returns {function(*)}
 */ export var setCurrentVideo = function(epg) {
    var isVod = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    var token = "";
    var stop = "";
    var tokenObject = getTokenObject();
    if (tokenObject && tokenObject.access_token) {
        token = tokenObject.access_token;
    }
    var nowInSeconds = Number.parseInt((new Date().getTime() / 1000).toFixed(0)) - config.timestampOffset;
    epg.video.now = nowInSeconds >= epg.video.stop ? 0 : NOW_EPG;
    var start = "begin=".concat(epg.video.start);
    stop = !epg.video.now ? "&end=".concat(epg.video.stop) : stop;
    epg.video.source = "".concat(API_URL, "/playlists").concat(isVod ? VOD : "", "/").concat(epg.channelId, "/playlist.m3u8?").concat(start).concat(stop, "&access_token=").concat(token);
    epg.isVod = isVod;
    if (localStorage && localStorage.getItem("debugCast")) {
        console.log("video: ", epg.video.source);
    }
    return function(dispatch) {
        dispatch({
            type: VIDEO_CURRENT,
            payload: epg
        });
    };
};
/**
 * Set video options - volume, current time, live, play/pause, mute
 * @param options
 * @returns {Function}
 */ export var setVideoOptions = function(options) {
    return function(dispatch) {
        dispatch({
            type: VIDEO_OPTIONS,
            payload: options
        });
    };
};
/**
 * Set video player container current state
 * @param state {object} - { isOpen: bool, isPlaying: bool }
 * @returns {function(*)}
 */ export var toggleVideoPlayerState = function(state) {
    return function(dispatch) {
        dispatch({
            type: VIDEO_PLAYER_TOGGLE,
            payload: state
        });
    };
};
/**
 * Set video player epgs
 * @param state
 * @returns {Function}
 */ export var setVideoPlayerEpgs = function(state) {
    return function(dispatch) {
        dispatch({
            type: VIDEO_PLAYER_EPGS,
            payload: state
        });
    };
};
/**
 * Set video player container all epgs for current channel
 * @param channelId {string}
 * @returns {function(*)}
 */ export var getAllEpgsForChannel = function(channelId) {
    var ajax = request({
        method: "get",
        url: "/epgs/".concat(channelId, "?all")
    });
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, reversedData;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        return [
                            4,
                            ajax
                        ];
                    case 1:
                        data = _state.sent().data;
                        reversedData = _to_consumable_array(data).reverse();
                        dispatch({
                            type: VIDEO_ALL_EPG,
                            payload: {
                                allEpgs: reversedData
                            }
                        });
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var resetVideo = function() {
    return function(dispatch) {
        dispatch({
            type: VIDEO_RESET,
            payload: {}
        });
    };
};
