function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { ADDRESS_INPUT, CITY_INPUT, COUNTRY_DROPDOWN, FIRST_NAME_INPUT, GDPR_BILLING_CHECKBOX, GDPR_SHIPPING_CHECKBOX, INPUT_VALIDATION_ALPHABET, LAST_NAME_INPUT, PHONE_INPUT, REGEX_ADDRESS, REGEX_ALPHABET, REGEX_ALPHANUMERIC, REGEX_PHONE, ZIP_INPUT } from "@config/constants";
import { isEmpty, isNil, isNull, isString } from "lodash";
/**
 *
 * @returns {string} ErrorMessage
 */ export function validateDeliveryFormField(form, field, i18n, validateAddress) {
    var value = form[field];
    var requiredFields = [
        FIRST_NAME_INPUT,
        LAST_NAME_INPUT,
        PHONE_INPUT,
        COUNTRY_DROPDOWN
    ].concat(_to_consumable_array(validateAddress ? [
        GDPR_SHIPPING_CHECKBOX,
        ZIP_INPUT,
        ADDRESS_INPUT,
        CITY_INPUT
    ] : [
        GDPR_BILLING_CHECKBOX
    ]));
    requiredFields = validateAddress ? _to_consumable_array(requiredFields).concat([
        ZIP_INPUT,
        ADDRESS_INPUT,
        CITY_INPUT
    ]) : requiredFields;
    var _obj;
    var validationRules = (_obj = {}, _define_property(_obj, FIRST_NAME_INPUT, {
        regex: REGEX_ALPHABET,
        errorMessage: i18n.errors["".concat(INPUT_VALIDATION_ALPHABET, "Characters")]
    }), _define_property(_obj, LAST_NAME_INPUT, {
        regex: REGEX_ALPHABET,
        errorMessage: i18n.errors["".concat(INPUT_VALIDATION_ALPHABET, "Characters")]
    }), _define_property(_obj, CITY_INPUT, {
        regex: REGEX_ALPHABET,
        errorMessage: i18n.errors["".concat(INPUT_VALIDATION_ALPHABET, "Characters")]
    }), _define_property(_obj, ZIP_INPUT, {
        regex: REGEX_ALPHANUMERIC,
        errorMessage: i18n.errors.zip
    }), _define_property(_obj, ADDRESS_INPUT, {
        regex: REGEX_ADDRESS,
        errorMessage: i18n.errors.address
    }), _define_property(_obj, PHONE_INPUT, {
        regex: REGEX_PHONE,
        errorMessage: i18n.errors.phone
    }), _obj);
    var valueNoWhitespace = isString(value) ? value.replace(/\s/g, "") : value;
    if (requiredFields.includes(field) && isEmpty(valueNoWhitespace) && !valueNoWhitespace) {
        return i18n.errors.requiredField;
    }
    if (validationRules[field] && isString(value)) {
        var _validationRules_field = validationRules[field], regex = _validationRules_field.regex, errorMessage = _validationRules_field.errorMessage;
        return valueNoWhitespace.match(regex) ? errorMessage : "";
    }
}
/**
 * Generates the request body for an order.
 * @param {object} orderItems - The order items object.
 * @returns {object} - The generated request body.
 */ export var generateRequestBody = function(orderItems) {
    var subscription = orderItems.subscription, r_addons = orderItems.r_addons, ot_addons = orderItems.ot_addons, coupon = orderItems.coupon;
    var plans = {
        plan: (subscription === null || subscription === void 0 ? void 0 : subscription.id) || "",
        addons: [].concat((ot_addons === null || ot_addons === void 0 ? void 0 : ot_addons.map(function(e) {
            return {
                id: e.id,
                recurring: false,
                quantity: e.quantity
            };
        })) || [], (r_addons === null || r_addons === void 0 ? void 0 : r_addons.map(function(e) {
            return {
                id: e.id,
                recurring: true,
                quantity: e.quantity
            };
        })) || []),
        coupons: !isNil(coupon) ? [
            coupon
        ] : []
    };
    return plans;
};
