function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { CLOSE_CONFIRMATION, CLOSE_GDPR, CLOSE_PIN_MODAL, CLOSE_PROMOTIONS, CLOSE_SNACKBAR, CLOSE_WRITE_TO_US, MODAL_CLOSE, MODAL_OPEN, OPEN_CONFIRMATION, OPEN_GDPR, OPEN_PIN_MODAL, OPEN_PROMOTIONS, OPEN_SNACKBAR, OPEN_WRITE_TO_US } from "@common/constants/action-types";
import { isEmpty } from "lodash";
var initialState = {
    // modalName: '',
    // modalProps: {},
    modals: [],
    isModalOpened: false,
    isWriteToUsOpened: false,
    isGdprOpened: false,
    gdprModalName: "",
    isPromotionsOpened: false,
    isSnackbarOpened: false
};
export default function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : initialState, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case MODAL_OPEN:
            return _object_spread_props(_object_spread({}, state), {
                isModalOpened: true,
                modals: _to_consumable_array(state.modals).concat([
                    {
                        modalName: action.payload.modalName,
                        modalProps: action.payload.modalProps
                    }
                ])
            });
        case MODAL_CLOSE:
            {
                var modals = _to_consumable_array(state.modals);
                modals.pop();
                return _object_spread_props(_object_spread({}, state), {
                    modals: modals,
                    isModalOpened: !isEmpty(modals)
                });
            }
        case OPEN_WRITE_TO_US:
            return _object_spread_props(_object_spread({}, state), {
                isWriteToUsOpened: true
            });
        case CLOSE_WRITE_TO_US:
            return _object_spread_props(_object_spread({}, state), {
                isWriteToUsOpened: false
            });
        case CLOSE_GDPR:
            return _object_spread_props(_object_spread({}, state), {
                isGdprOpened: false
            });
        case OPEN_GDPR:
            return _object_spread_props(_object_spread({}, state), {
                gdprModalName: action.payload,
                isGdprOpened: true
            });
        case OPEN_PROMOTIONS:
            return _object_spread_props(_object_spread({}, state), {
                isPromotionsOpened: true,
                prismicPlan: action.payload
            });
        case CLOSE_PROMOTIONS:
            delete state.prismicPlan;
            return _object_spread_props(_object_spread({}, state), {
                isPromotionsOpened: false
            });
        case OPEN_CONFIRMATION:
            return _object_spread_props(_object_spread({}, state), {
                isConfirmationOpened: true,
                confirmationModalProps: action.payload
            });
        case CLOSE_CONFIRMATION:
            return _object_spread_props(_object_spread({}, state), {
                isConfirmationOpened: false
            });
        case OPEN_SNACKBAR:
            return _object_spread_props(_object_spread({}, state), {
                isSnackbarOpened: true
            });
        case CLOSE_SNACKBAR:
            return _object_spread_props(_object_spread({}, state), {
                isSnackbarOpened: false
            });
        case OPEN_PIN_MODAL:
            return _object_spread_props(_object_spread({}, state), {
                isPinModalOpened: true,
                pinModalProps: action.payload.pinModalProps
            });
        case CLOSE_PIN_MODAL:
            return _object_spread_props(_object_spread({}, state), {
                isPinModalOpened: false,
                pinModalProps: {}
            });
        default:
            return state;
    }
}
