import { USER_GET_COUNTRY } from "@common/constants/action-types";
export default function(state, action) {
    switch(action.type){
        case USER_GET_COUNTRY:
            var _action_payload;
            return (_action_payload = action.payload) !== null && _action_payload !== void 0 ? _action_payload : "";
        default:
            return state !== null && state !== void 0 ? state : "";
    }
}
