export var APP_GET_CONFIG = "APP_GET_CONFIG";
export var APP_GET_SUBLANGS = "APP_GET_SUBLANGS";
export var APP_GET_AUDIOLANGS = "APP_GET_AUDIOLANGS";
export var CATEGORIES_GET = "CATEGORIES_GET";
export var LIVE_CHANNELS_GET = "LIVE_CHANNELS_GET";
export var FILTERED_LIVE_CHANNELS_GET = "FILTERED_LIVE_CHANNELS_GET";
export var LOGOS_SET = "LOGOS_SET";
export var RECORDED_CLEAR_CATEGORIES_EPGS = "RECORDED_CLEAR_CATEGORIES_EPGS";
export var RECORDED_CLEAR_SUBCATEGORIES_EPGS = "RECORDED_CLEAR_SUBCATEGORIES_EPGS";
export var RECORDED_GET_CATEGORY_EPGS = "RECORDED_GET_CATEGORY_EPGS";
export var RECORDED_GET_SUBCATEGORY_EPGS = "RECORDED_GET_SUBCATEGORY_EPGS";
export var RECORDED_CLEAR_CATEGORY_LIST = "RECORDED_CLEAR_CATEGORY_LIST";
export var RECORDED_GET_CATEGORY_LIST_NEXT_PAGE = "RECORDED_GET_CATEGORY_LIST_NEXT_PAGE";
export var RECORDED_CLEAR_SUBCATEGORY_LIST = "RECORDED_CLEAR_SUBCATEGORY_LIST";
export var RECORDED_GET_SUBCATEGORY_LIST_NEXT_PAGE = "RECORDED_GET_SUBCATEGORY_LIST_NEXT_PAGE";
export var TRANSLATIONS_GET_COMMON = "TRANSLATIONS_GET_COMMON";
export var VIDEO_CURRENT = "VIDEO_CURRENT";
export var VIDEO_PLAYER_TOGGLE = "VIDEO_PLAYER_TOGGLE";
export var VIDEO_ALL_EPG = "VIDEO_ALL_EPG";
export var VIDEO_RESET = "VIDEO_RESET";
export var VIDEO_OPTIONS = "VIDEO_OPTIONS";
export var VIDEO_PLAYER_EPGS = "VIDEO_PLAYER_EPGS";
export var VIDEO_SET_AFTER_SUBSCRIPTION = "VIDEO_SET_AFTER_SUBSCRIPTION";
export var VIDEO_RESET_AFTER_SUBSCRIPTION = "VIDEO_RESET_AFTER_SUBSCRIPTION";
export var CURRENT_CHANNEL_SET = "CURRENT_CHANNEL_SET";
export var CURRENT_CHANNEL_SET_HISTORY_FOR_A_DAY = "CURRENT_CHANNEL_SET_HISTORY_FOR_A_DAY";
export var AUTH_DELETE_TOKEN = "AUTH_DELETE_TOKEN";
export var AUTH_SIGN_IN = "AUTH_SIGN_IN";
export var AUTH_SET_LAST_REQUESTED_ROUTE = "AUTH_SET_LAST_REQUESTED_ROUTE";
export var MODAL_OPEN = "MODAL_OPEN";
export var MODAL_CLOSE = "MODAL_CLOSE";
export var CLOSE_WRITE_TO_US = "CLOSE_WRITE_TO_US";
export var OPEN_WRITE_TO_US = "OPEN_WRITE_TO_US";
export var OPEN_CONFIRMATION = "OPEN_CONFIRMATION";
export var CLOSE_CONFIRMATION = "CLOSE_CONFIRMATION";
export var OPEN_GDPR = "OPEN_GDPR";
export var CLOSE_GDPR = "CLOSE_GDPR";
export var OPEN_PROMOTIONS = "OPEN_PROMOTIONS";
export var CLOSE_PROMOTIONS = "CLOSE_PROMOTIONS";
export var OPEN_SNACKBAR = "OPEN_SNACKBAR";
export var CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export var OPEN_PIN_MODAL = "OPEN_PIN_MODAL";
export var CLOSE_PIN_MODAL = "CLOSE_PIN_MODAL";
export var REDIRECT_TO_TOO_MANY_USERS = "REDIRECT_TO_TOO_MANY_USERS";
export var MOST_WATCHED_GET_EPGS = "MOST_WATCHED_GET_EPGS";
export var LAST_WATCHED_GET_EPGS = "LAST_WATCHED_GET_EPGS";
export var SEARCH_SET_RESULT = "SEARCH_SET_RESULT";
export var SEARCH_FILTER_RESULT = "SEARCH_FILTER_RESULT";
export var SEARCH_CHANNEL_RESULT = "SEARCH_CHANNEL_RESULT";
export var SEARCH_CLEAR = "SEARCH_CLEAR";
export var CHANGE_SCREEN_TYPE = "CHANGE_SCREEN_TYPE";
export var USER_GET_DETAILS = "USER_GET_DETAILS";
export var USER_CONFIRM_DELETION = "USER_CONFIRM_DELETION";
export var USER_GET_PROFILE_SETTINGS = "USER_GET_PROFILE_SETTINGS";
export var USER_SET_PROFILE_SETTINGS = "USER_SET_PROFILE_SETTINGS";
export var USER_GET_FULL_DETAILS = "USER_GET_FULL_DETAILS";
export var USER_GET_INVOICES = "USER_GET_INVOICES";
export var USER_REMOVE_DETAILS = "USER_REMOVE_DETAILS";
export var USER_GET_INVOICE_URL = "USER_GET_INVOICE_URL";
export var SUBSCRIPTIONS_GET = "SUBSCRIPTIONS_GET";
export var ADDONS_GET = "ADDONS_GET";
export var CATEGORIES_LIVE_GET = "CATEGORIES_LIVE_GET";
export var HEADER_TOGGLE = "HEADER_TOGGLE";
export var SERVER_TIME_SET = "SERVER_TIME_SET";
export var APP_GET_APP_RESOLUTIONS = "APP_GET_APP_RESOLUTIONS";
export var APP_GET_CHANNEL_SETTINGS = "APP_GET_CHANNEL_SETTINGS";
export var APP_GET_CHANNEL_DEFAULT_PIN = "APP_GET_CHANNEL_DEFAULT_PIN";
export var PRISMIC_GET_SUBSCRIPTIONS = "PRISMIC_GET_SUBSCRIPTIONS";
export var PRISMIC_GET_SUBSCRIPTIONS_ALL = "PRISMIC_GET_SUBSCRIPTIONS_ALL";
export var USER_GET_COUNTRY = "USER_GET_COUNTRY";
