function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import { store } from "@common";
import { CATEGORIES_GET, RECORDED_CLEAR_CATEGORIES_EPGS, RECORDED_CLEAR_CATEGORY_LIST, RECORDED_CLEAR_SUBCATEGORIES_EPGS, RECORDED_CLEAR_SUBCATEGORY_LIST, RECORDED_GET_CATEGORY_EPGS, RECORDED_GET_CATEGORY_LIST_NEXT_PAGE, RECORDED_GET_SUBCATEGORY_EPGS, RECORDED_GET_SUBCATEGORY_LIST_NEXT_PAGE } from "@common/constants/action-types";
import { partitionArray } from "@common/services/helpers";
import request from "@common/services/request";
import { checkLockStatusForChannel, checkVisibleStatusForChannel } from "@common/utils/helpers";
import { API_MAX_CATEGORIES_LIMIT, VOD } from "@config/constants";
import { isEmpty } from "lodash";
import { getProfileSettings } from "@common/actions/profile-settings";
var requestFilteredEpgs = function() {
    var _ref = _async_to_generator(function(url, data) {
        var response, profileSettings, entries;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        request({
                            method: "post",
                            url: url,
                            data: data
                        })
                    ];
                case 1:
                    response = _state.sent();
                    // return response;
                    profileSettings = store.getState().profileSettings;
                    if (!isEmpty(profileSettings.web_settings.DeviceSettingsItemList)) return [
                        3,
                        3
                    ];
                    return [
                        4,
                        store.dispatch(getProfileSettings())
                    ];
                case 2:
                    _state.sent();
                    profileSettings = store.getState().profileSettings;
                    _state.label = 3;
                case 3:
                    entries = Object.entries(response.data).map(function(param) {
                        var _param = _sliced_to_array(param, 2), categoryId = _param[0], epgs = _param[1];
                        return [
                            categoryId,
                            epgs.filter(function(epg) {
                                return checkVisibleStatusForChannel(profileSettings, epg.chanId) && !checkLockStatusForChannel(profileSettings, epg.chanId);
                            })
                        ];
                    });
                    return [
                        2,
                        _object_spread_props(_object_spread({}, response), {
                            data: Object.fromEntries(entries)
                        })
                    ];
            }
        });
    });
    return function requestFilteredEpgs(url, data) {
        return _ref.apply(this, arguments);
    };
}();
export var getRecordedCategories = function() {
    var vod = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
    var ajax = request({
        method: "get",
        url: "/epgs".concat(vod ? VOD : "", "/categories").concat(vod ? "/all" : "")
    });
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            ajax
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: CATEGORIES_GET,
                            payload: data
                        });
                        return [
                            3,
                            3
                        ];
                    case 2:
                        error = _state.sent();
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
// FOR THE PAGE WITH ALL CATEGORIES:
export var getListsForManyCategories = function(data) {
    var vod = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    var promises = partitionArray(data.categories, API_MAX_CATEGORIES_LIMIT).map(function(categories) {
        return requestFilteredEpgs("/epgs".concat(vod ? VOD : "", "/categories/many"), {
            categories: categories
        });
    });
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var res, accumulatedData;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        return [
                            4,
                            Promise.all(promises)
                        ];
                    case 1:
                        res = _state.sent();
                        accumulatedData = res.reduce(function(accumulated, current) {
                            return _object_spread({}, accumulated, current.data);
                        }, {});
                        dispatch({
                            type: RECORDED_GET_CATEGORY_EPGS,
                            payload: accumulatedData
                        });
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var getNextPageForCategory = function(queryData) {
    var vod = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    var ajax = requestFilteredEpgs("/epgs".concat(vod ? VOD : "", "/categories/many"), queryData);
    var catId = queryData.categories[0].id;
    return function() {
        var _ref = _async_to_generator(function(dispatch, getState) {
            var data, prevState;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        return [
                            4,
                            ajax
                        ];
                    case 1:
                        data = _state.sent().data;
                        prevState = getState().recorded.categoriesEpgs[catId];
                        dispatch({
                            type: RECORDED_GET_CATEGORY_EPGS,
                            payload: data[catId].length > 0 ? data : prevState
                        });
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch, getState) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var getPrevPageForCategory = function(queryData) {
    var vod = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    var ajax = requestFilteredEpgs("/epgs".concat(vod ? VOD : "", "/categories/many"), queryData);
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        return [
                            4,
                            ajax
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: RECORDED_GET_CATEGORY_EPGS,
                            payload: data
                        });
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var clearCategoriesEpgs = function() {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            return _ts_generator(this, function(_state) {
                dispatch({
                    type: RECORDED_CLEAR_CATEGORIES_EPGS,
                    payload: null
                });
                return [
                    2
                ];
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
// FOR THE PAGE WITH ALL SUBCATEGORIES FOR A CATEGORY:
export var getListsForManySubcategories = function(data) {
    var vod = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    var promises = partitionArray(data.subcategories, API_MAX_CATEGORIES_LIMIT).map(function(subcategories) {
        return requestFilteredEpgs("/epgs".concat(vod ? VOD : "", "/subcategories/many"), {
            subcategories: subcategories
        });
    });
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var res, accumulatedData;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        return [
                            4,
                            Promise.all(promises)
                        ];
                    case 1:
                        res = _state.sent();
                        accumulatedData = res.reduce(function(accumulated, current) {
                            return _object_spread({}, accumulated, current.data);
                        }, {});
                        dispatch({
                            type: RECORDED_GET_SUBCATEGORY_EPGS,
                            payload: accumulatedData
                        });
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var getNextPageForSubcategory = function(queryData) {
    var vod = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    var ajax = requestFilteredEpgs("/epgs".concat(vod ? VOD : "", "/subcategories/many"), queryData);
    var catId = queryData.subcategories[0].id;
    return function() {
        var _ref = _async_to_generator(function(dispatch, getState) {
            var data, prevState;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        return [
                            4,
                            ajax
                        ];
                    case 1:
                        data = _state.sent().data;
                        prevState = getState().recorded.subcategoriesEpgs[catId];
                        dispatch({
                            type: RECORDED_GET_SUBCATEGORY_EPGS,
                            payload: data[catId].length > 0 ? data : prevState
                        });
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch, getState) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var getPrevPageForSubcategory = function(queryData) {
    var vod = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    var ajax = requestFilteredEpgs("/epgs".concat(vod ? VOD : "", "/subcategories/many"), queryData);
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        return [
                            4,
                            ajax
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: RECORDED_GET_SUBCATEGORY_EPGS,
                            payload: data
                        });
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var clearSubcategoriesEpgs = function() {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            return _ts_generator(this, function(_state) {
                dispatch({
                    type: RECORDED_CLEAR_SUBCATEGORIES_EPGS,
                    payload: null
                });
                return [
                    2
                ];
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
// FOR THE PAGE WITH LISTING FOR A CATEGORY:
export var getCategoryListNextPage = function(queryData) {
    var vod = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    var ajax = requestFilteredEpgs("/epgs".concat(vod ? VOD : "", "/categories/many"), queryData);
    var catId = queryData.categories[0].id;
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        return [
                            4,
                            ajax
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: RECORDED_GET_CATEGORY_LIST_NEXT_PAGE,
                            payload: {
                                data: data,
                                id: catId
                            }
                        });
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var clearCategoryList = function(id) {
    return function(dispatch) {
        dispatch({
            type: RECORDED_CLEAR_CATEGORY_LIST,
            payload: {
                id: id
            }
        });
    };
};
// FOR THE PAGE WITH LISTING FOR A SUBCATEGORY:
export var getSubcategoryListNextPage = function(queryData) {
    var vod = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    var ajax = requestFilteredEpgs("/epgs".concat(vod ? VOD : "", "/subcategories/many"), queryData);
    var subCatId = queryData.subcategories[0].id;
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        return [
                            4,
                            ajax
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: RECORDED_GET_SUBCATEGORY_LIST_NEXT_PAGE,
                            payload: {
                                data: data,
                                id: subCatId
                            }
                        });
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var clearSubcategoryList = function(id) {
    return function(dispatch) {
        dispatch({
            type: RECORDED_CLEAR_SUBCATEGORY_LIST,
            payload: {
                id: id
            }
        });
    };
};
