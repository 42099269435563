function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { USER_CONFIRM_DELETION, USER_GET_DETAILS, USER_GET_FULL_DETAILS, USER_GET_INVOICES, USER_GET_INVOICE_URL, USER_GET_PROFILE_SETTINGS, USER_REMOVE_DETAILS } from "@common/constants/action-types";
import { COUNTRIES } from "@config/countries";
export default function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case USER_GET_FULL_DETAILS:
            var _action_payload_data_subscription;
            var selectedCountryBilling = "";
            var selectedCountryShipping = "";
            var predefinedCountry = "";
            var billingAddress = action.payload.data.billingAddress;
            var shippingAddress = ((_action_payload_data_subscription = action.payload.data.subscription) === null || _action_payload_data_subscription === void 0 ? void 0 : _action_payload_data_subscription[0]) && action.payload.data.subscription[0].shippingAddress;
            if (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.country) {
                selectedCountryBilling = COUNTRIES.filter(function(countryObj) {
                    return countryObj.code === billingAddress.country;
                });
            } else {
                predefinedCountry = COUNTRIES.filter(function(countryObj) {
                    return countryObj.code === action.payload.data.countryLastLogin;
                });
            }
            if (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.country) {
                selectedCountryShipping = COUNTRIES.filter(function(countryObj) {
                    return countryObj.code === shippingAddress.country;
                });
            } else {
                predefinedCountry = COUNTRIES.filter(function(countryObj) {
                    return countryObj.code === action.payload.data.countryLastLogin;
                });
            }
            return _object_spread_props(_object_spread({}, state.user, action.payload.data), {
                predefinedCountry: predefinedCountry && predefinedCountry[0] ? predefinedCountry[0].name : "",
                predefinedCountryCode: predefinedCountry && predefinedCountry[0] ? predefinedCountry[0].code : "",
                countryNameBilling: selectedCountryBilling && selectedCountryBilling[0] ? selectedCountryBilling[0].name : "",
                countryNameShipping: selectedCountryShipping && selectedCountryShipping[0] ? selectedCountryShipping[0].name : ""
            });
        case USER_GET_DETAILS:
            return _object_spread({}, action.payload.data);
        case USER_GET_INVOICES:
            return _object_spread_props(_object_spread({}, state), {
                invoicesList: action.payload.data
            });
        case USER_GET_INVOICE_URL:
            return _object_spread_props(_object_spread({}, state), {
                invoice: action.payload.data
            });
        case USER_CONFIRM_DELETION:
            return _object_spread_props(_object_spread({}, state), {
                isDeletionConfirmed: action.payload
            });
        case USER_REMOVE_DETAILS:
            return {
                email: state.email
            };
        default:
            return state;
    }
}
