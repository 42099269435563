import { getSessionLockTime } from "@common/services/helpers";
import { useEffect } from "react";
var useGiveUpControl = function(props, giveUpControl) {
    var profileSettings = props.profileSettings, videoPlayer = props.videoPlayer, liveChannels = props.liveChannels, filteredLiveChannels = props.filteredLiveChannels;
    var lockPeriod = profileSettings.web_settings.lockPeriod;
    var timeToLockChannels = getSessionLockTime();
    useEffect(function() {
        giveUpControl();
    }, [
        videoPlayer.video.source,
        timeToLockChannels,
        lockPeriod,
        filteredLiveChannels,
        liveChannels
    ]);
};
export default useGiveUpControl;
