function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { AUTH_DELETE_TOKEN, AUTH_SET_LAST_REQUESTED_ROUTE, AUTH_SIGN_IN } from "@common/constants/action-types";
import { getTokenObject } from "@common/services/helpers";
import { REDIRECT_DEFAULT_ROUTE } from "@config/constants";
var initialToken = "";
var tokenObject = getTokenObject();
if (tokenObject && tokenObject.access_token) {
    initialToken = tokenObject.access_token;
}
export default function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        grant_type: "client_credentials",
        lastRequestedRoute: "",
        redirect: REDIRECT_DEFAULT_ROUTE,
        rememberme: true,
        repeatPassword: "",
        token: initialToken
    }, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case AUTH_DELETE_TOKEN:
            var redirect = action.payload.redirect || REDIRECT_DEFAULT_ROUTE;
            return _object_spread_props(_object_spread({}, state), {
                redirect: redirect,
                token: ""
            });
        case AUTH_SET_LAST_REQUESTED_ROUTE:
            return _object_spread_props(_object_spread({}, state), {
                lastRequestedRoute: action.payload
            });
        case AUTH_SIGN_IN:
            var access_token = action.payload.access_token;
            return _object_spread_props(_object_spread({}, state), {
                token: access_token
            });
        default:
            return state;
    }
}
