function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { useLayoutEffect } from "react";
export { useLayoutEffect };
// Modal sheet uses these methods :) but aria does't compile to es5
export var isIOS = function() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};
export var chain = function() {
    for(var _len = arguments.length, callbacks = new Array(_len), _key = 0; _key < _len; _key++){
        callbacks[_key] = arguments[_key];
    }
    return function() {
        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
            args[_key] = arguments[_key];
        }
        callbacks.forEach(function(callback) {
            if (typeof callback === "function") {
                callback.apply(void 0, _to_consumable_array(args));
            }
        });
    };
};
export var getScrollParent = function(element) {
    if (!(element instanceof HTMLElement)) {
        return document.scrollingElement || document.documentElement;
    }
    var style = getComputedStyle(element);
    var overflowRegex = /(auto|scroll)/;
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
        return element;
    }
    return getScrollParent(element.parentElement) || document.scrollingElement || document.documentElement;
};
