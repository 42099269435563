function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import { CURRENT_CHANNEL_SET, CURRENT_CHANNEL_SET_HISTORY_FOR_A_DAY } from "@common/constants/action-types";
import config from "@common/services/config";
import { getToday } from "@common/services/helpers";
import request from "@common/services/request";
export var setCurrentChannel = function(param) {
    var channel = param.channel, linkIsShown = param.linkIsShown;
    return function(dispatch) {
        dispatch({
            type: CURRENT_CHANNEL_SET,
            payload: {
                channel: channel,
                linkIsShown: linkIsShown
            }
        });
    };
};
export var getChannelHistory = function(channelId) {
    var today = getToday();
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var currentEpgs, isTodayEpgsRequestPending, isTomorrowEpgsRequestPending, currentDay, currentDate, currentKey, query, ajax, data;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        // Use this variable to check in the while
                        // clause if the current data length is > 0.
                        // After every request this variable
                        // gets the new data assigned.
                        currentEpgs = [];
                        isTodayEpgsRequestPending = true;
                        isTomorrowEpgsRequestPending = true;
                        // Start fetching from tomorrow, because
                        // in order to show the next 13 shows
                        // after the current live show
                        // (business logic) it may be necessary
                        // to search the next day.
                        currentDay = 1;
                        currentDate = new Date(today.setDate(today.getDate() + 1));
                        currentKey = currentDate.getTime();
                        _state.label = 1;
                    case 1:
                        query = {
                            channels: [
                                {
                                    id: channelId,
                                    offset: currentDay,
                                    utcOffset: -config.defaultOffset
                                }
                            ]
                        };
                        ajax = request({
                            method: "post",
                            url: "/epgs/day",
                            data: query
                        });
                        if (currentDay === 1) {
                            isTodayEpgsRequestPending = true;
                        } else {
                            isTomorrowEpgsRequestPending = true;
                        }
                        dispatch({
                            type: CURRENT_CHANNEL_SET_HISTORY_FOR_A_DAY,
                            payload: {
                                isTomorrowEpgsRequestPending: isTomorrowEpgsRequestPending,
                                isTodayEpgsRequestPending: isTodayEpgsRequestPending
                            }
                        });
                        return [
                            4,
                            ajax
                        ];
                    case 2:
                        data = _state.sent().data;
                        isTomorrowEpgsRequestPending = false;
                        isTodayEpgsRequestPending = false;
                        if (data.length > 0) {
                            currentEpgs = data;
                            dispatch({
                                type: CURRENT_CHANNEL_SET_HISTORY_FOR_A_DAY,
                                payload: {
                                    epgs: currentEpgs,
                                    id: currentKey,
                                    isTomorrowEpgsRequestPending: isTomorrowEpgsRequestPending,
                                    isTodayEpgsRequestPending: isTodayEpgsRequestPending
                                }
                            });
                        } else {
                            isTomorrowEpgsRequestPending = false;
                            dispatch({
                                type: CURRENT_CHANNEL_SET_HISTORY_FOR_A_DAY,
                                payload: {
                                    isTomorrowEpgsRequestPending: isTomorrowEpgsRequestPending,
                                    isTodayEpgsRequestPending: isTodayEpgsRequestPending
                                }
                            });
                            return [
                                3,
                                4
                            ];
                        }
                        currentDay--;
                        currentDate = new Date(currentDate.setDate(currentDate.getDate() - 1));
                        currentKey = currentDate.getTime();
                        _state.label = 3;
                    case 3:
                        if (currentEpgs.length > 0) return [
                            3,
                            1
                        ];
                        _state.label = 4;
                    case 4:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var getChannelHistoryForOffset = function(channelId, timestamp, offset, utcOffset) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var query, ajax, data;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        query = {
                            channels: [
                                {
                                    offset: offset,
                                    utcOffset: utcOffset,
                                    id: channelId
                                }
                            ]
                        };
                        ajax = request({
                            method: "post",
                            url: "/epgs/day",
                            data: query
                        });
                        return [
                            4,
                            ajax
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: CURRENT_CHANNEL_SET_HISTORY_FOR_A_DAY,
                            payload: {
                                epgs: data,
                                id: timestamp
                            }
                        });
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
