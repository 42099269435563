import { CLOSE_CONFIRMATION, CLOSE_GDPR, CLOSE_PIN_MODAL, CLOSE_PROMOTIONS, CLOSE_SNACKBAR, CLOSE_WRITE_TO_US, MODAL_CLOSE, MODAL_OPEN, OPEN_CONFIRMATION, OPEN_GDPR, OPEN_PIN_MODAL, OPEN_PROMOTIONS, OPEN_SNACKBAR, OPEN_WRITE_TO_US } from "@common/constants/action-types";
export var openModal = function(modalName, modalProps) {
    return function(dispatch) {
        dispatch({
            type: MODAL_OPEN,
            payload: {
                modalName: modalName,
                modalProps: modalProps
            }
        });
    };
};
export var closeModal = function() {
    return function(dispatch) {
        dispatch({
            type: MODAL_CLOSE
        });
    };
};
export var openWriteToUs = function() {
    return function(dispatch) {
        dispatch({
            type: OPEN_WRITE_TO_US
        });
    };
};
export var closeWriteToUs = function() {
    return function(dispatch) {
        dispatch({
            type: CLOSE_WRITE_TO_US
        });
    };
};
export var openConfirmation = function(modalProps) {
    return function(dispatch) {
        dispatch({
            type: OPEN_CONFIRMATION,
            payload: modalProps
        });
    };
};
export var closeConfirmation = function() {
    return function(dispatch) {
        dispatch({
            type: CLOSE_CONFIRMATION
        });
    };
};
export var openGdpr = function(modalName) {
    return function(dispatch) {
        dispatch({
            type: OPEN_GDPR,
            payload: modalName
        });
    };
};
export var closeGdpr = function() {
    return function(dispatch) {
        dispatch({
            type: CLOSE_GDPR
        });
    };
};
export var openPromotions = function(prismicPlan) {
    return function(dispatch) {
        dispatch({
            type: OPEN_PROMOTIONS,
            payload: prismicPlan
        });
    };
};
export var closePromotions = function() {
    return function(dispatch) {
        dispatch({
            type: CLOSE_PROMOTIONS
        });
    };
};
export var openSnackbar = function() {
    return function(dispatch) {
        dispatch({
            type: OPEN_SNACKBAR
        });
    };
};
export var closeSnackbar = function() {
    return function(dispatch) {
        dispatch({
            type: CLOSE_SNACKBAR
        });
    };
};
export var openPinModal = function(modalProps) {
    return function(dispatch) {
        dispatch({
            type: OPEN_PIN_MODAL,
            payload: {
                isPinModalOpened: true,
                pinModalProps: modalProps
            }
        });
    };
};
export var closePinModal = function() {
    return function(dispatch) {
        dispatch({
            type: CLOSE_PIN_MODAL,
            payload: {
                isPinModalOpened: false
            }
        });
    };
};
