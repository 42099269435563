module.exports = {
    HTML_TEMPLATE_LANG: "bg",
    HTML_TEMPLATE_TITLE: "Telnet | Цифрова телевизия базирана на нова технология",
    HTML_TEMPLATE_DESCRIPTION: "Българска интерактивна телевизия с HD качество. TV канали на живо с, български сериали, новини, филми, спорт и още. Онлайн стрийминг на bnt, btv, nova, kino nova, btv cinema, btv action, diema, eurosport и др.",
    HTML_TEMPLATE_KEYWORDS: "интерактивна онлайн телевизия, на живо, лайв стрийминг, btv, nova, bnt, diema, eurosport, btv action, btv cinema, kino nova, bitelevision, ring",
    HTML_TEMPLATE_OG_TITLE: "Telnet | Интерактивна българска онлайн телевизия, на живо.",
    HTML_TEMPLATE_OG_DESCRIPTION: "Интерактивна онлайн телевизия, с HD качество. Сериали, новини, филми, кино, спорт и още.",
    HTML_TEMPLATE_OG_IMAGE: "/assets/media/facebook.png",
    GOOGLE_SIGNIN_CLIENT_ID: "373218458272-epfhi22cecdthvuclih12c8lle16h5nn.apps.googleusercontent.com",
    // HTML_TEMPLATE_GOOGLE_TAG_MANAGER_ID
    HTML_TEMPLATE_GOOGLE_CHROMECAST_ID: "1868E374"
};
