// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
var _1 = `"Roboto","Roboto",Sans-Serif`;
export { _1 as "font-weight-light" };
var _2 = `"Roboto Medium","Roboto",Sans-Serif`;
export { _2 as "font-weight-medium" };
var _3 = `"Roboto Bold","Roboto",Sans-Serif`;
export { _3 as "font-weight-bold" };
var _4 = `"RobotoCondensed Bold","RobotoCondensed",Sans-Serif`;
export { _4 as "font-secondary-weight-bold" };
export default ___CSS_LOADER_EXPORT___;
