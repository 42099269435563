function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
var _obj;
export var commonTranslations = {
    common: {
        lockedChannel: "Заключен канал",
        rightNow: "Веднага",
        watch: "Гледай",
        director: "Режисьор",
        actors: "Актьори",
        season: "Сезон",
        episode: "Епизод",
        more: "Повече",
        back: "Oбратно",
        confirm: "Потвърди",
        seeMore: "Виж още",
        seeMoreAlt: "Виж повече",
        close: "Затвори",
        warning: "Внимание"
    },
    account: {
        areYouSureCancelAddon: "Сигурни ли сте, че искате да прекратите този допълнителен пакет?",
        areYouSureRenewSubscription: "След натискането на бутона ПОТВЪРДИ е възможно да се извърши плащане. Сигурни ли сте, че искате да подновите абонамента си?",
        statusDeactivated: "Деактивиран",
        statusNonRenewable: "Не се подновява",
        renew: "Поднови",
        year: "година"
    },
    settings: {
        modalTitle: "Настройки",
        channelsView: "Канали",
        noChannelsFound: "Няма намерени канали с въведените критерии, които да са включени във вашия абонаментен план. Опитайте изписването на канала по друг начин",
        videoView: "Видео",
        changeView: "Смяна на изглед",
        selectView: "Избери изглед на каналите в НА ЖИВО",
        controlChannels: "Управление на каналите",
        controlVideos: "Видео настройки",
        ParentalControlShort: "Родителски контрол",
        changeChannelsOrder: "Подреди",
        lockChannel: "Заключи",
        hideChannel: "Скрий",
        vertical: "Вертикално",
        horizontal: "Хоризонтално",
        darkMode: "Тъмна визия",
        timeToUnlockAChannel: "Време за отключен канал",
        alwaysLock: "Винаги заключен",
        unlockAll: "Отключи всички",
        period: "Период",
        minutes: "Минути",
        minute: "Минута",
        untilTheSessionEnd: "До края на сесията",
        changePin: "Смяна на ПИН",
        currentPin: "Текущ ПИН",
        change: "Промяна",
        enterOldPIN: "Въведете стария ПИН",
        enterNewPIN: "Въведете новия ПИН",
        enterPIN: "Въведете ПИН",
        repeatNewPIN: "Повторете новия ПИН",
        PINDontMatch: "ПИН не съвпадат",
        incorrentPin: "Неправилен ПИН",
        addFourDigitPin: "ПИН трябва да е само от четири на брой цифри",
        changeSettingsSuccess: "Успешно променихте настройките!",
        PINFail: "Грешен ПИН",
        unlockChannels: "Каналите са отключени",
        unlockChannel: "Каналът е отключен",
        PINSuccess: "Успешно променихте вашият ПИН",
        ResetView: "Нулиране",
        resetViewTitle: "Нулиране на настройки",
        resetChannelSettings: "Нулирай",
        select: "Избери",
        AudioSubView: "Аудио / Субтитри",
        audioSettings: "Аудио настройки",
        subSettings: "Субтитри настройки",
        bulgarianLang: "Български",
        defaultLang: "По подразбиране",
        selectLanguage: "Изберете език",
        auto: "Автоматично",
        resolution1080: "FHD (1080p)",
        resolution720: "HD (720p)",
        resolution576: "Високо (576p)",
        resolution432: "Средно (432p)",
        resolution288: "Ниско (288p)",
        resolution1080desc: "Най-доброто видео качество, при връзка до 2.5GB на час",
        resolution720desc: "Най-доброто видео качество, при връзка до 2.2GB на час",
        resolution576desc: "Най-доброто видео качество, при връзка до 1.3GB на час",
        resolution432desc: "Най-доброто видео качество, при връзка до 0.8GB на час",
        resolution288desc: "Най-доброто видео качество, при връзка до 0.4GB на час"
    },
    pin: {
        title: "Отключване на канал",
        pleaseEnterPin: "Моля, въведете ПИН:",
        incorrentPin: "Неправилен ПИН",
        unlock: "Отключи"
    },
    reset: {
        areYouSureReset: "Сигурни ли сте, че искате да върнете фабричните настройки? Действието е необратимо",
        successReset: "Успешно нулирахте настройки",
        resetSelected: "Нулирай избраните",
        resetAll: "Нулиране на всички",
        channelSettings: "Настройки на канали",
        channelSettingsSub: "Връщане до началното състояние на избраните настройки, контролиращи каналите.",
        globalSetting: "Глобални настройки",
        globalSettingSub: "Връщане до началното състояние на избраните настройки, действащи върху всички канали.",
        individualSettings: "Индивидуални настройки на каналите",
        individualSettingsSub: "Връщане до началното състояние на избраните настройки, които са ръчно зададени от видео плеъра за отделните канали",
        reorder: "Пренареждане",
        hide: "Скриване",
        lock: "Заключване",
        audio: "Аудио",
        video: "Видео",
        subtitles: "Субтитри"
    },
    order: (_obj = {
        currentSubscription: "Текущ<br/>план",
        onlyOnAndroidTV: "Каналите са достъпни за гледане само през Android TV устройство",
        periodOfBilling: "Период за фактуриране",
        quantity: "Количество",
        activePeriod: "Активен период",
        promoCode: "Промо код",
        number: "Номер",
        expiry: "Валидност",
        currentPaymentMethods: "Текущи платежни методи",
        enterPaymentMethod: "Въведете платежен метод",
        changePaymentMethod: "Променете платежен метод",
        unsuccessfulPayment: "Неуспешно плащане!",
        tvBox: "ТВ БОКС",
        addon: "Допълнителен пакет",
        invalidCoupon: "Невалиден промо код",
        pickLocation: "Изберете местоположение",
        monthShort: "мес",
        enterPromoCode: "Въведи код",
        prepaid: "Предплатени",
        promotional: "Промоционални",
        selectedServices: "Избрани услуги",
        areYouSureToExit: "Сигурни ли сте, че искате да излезете, докато плащането се обработва?",
        areYouSureContinue: "С натискане на бутона ПЛАТИ ще извършите плащане. Сигурни ли сте, че искате да продължите?",
        back: "НАЗАД",
        pay: "ПЛАТИ",
        partition: "Разбивка",
        byMonths: "по месеци",
        prepaymentForPeriod: "Предплащане за периода",
        youAlreadyHavePaid: "Вече имате платен {1} абонамент. Ако продължите, ще смените периода на фактуриране.",
        remaining: "Остава",
        nextBillingDate: "Следваща дата на фактуриране за пълния период от {1}",
        chooseSubscription: "Избери абонаментен план",
        chooseAddons: "Избери допълнителни пакети",
        month: "{1} месец",
        months: "{1} месеца",
        channelsNumber: "{1} TV канала ({2}HD)",
        channels: "{1} канала",
        tvChannels: "TV канала",
        enter: "Въведи",
        allChannels: "{1} канала",
        billingDetails: "Адрес за фактуриране",
        billingDetailsSubtitle: "Задължително попълнете с латински букви, без символи",
        billingDetailsForDelivery: "Настоящият адрес е валиден и за доставка.",
        iConsent: "Съгласен съм",
        billingDetailsConsent: "да предоставя данните за фактура",
        billingDetailsConsentDelivery: "да предоставя данните за доставка",
        includedIn: "Включен в",
        period: "Период",
        basicTitle: "Безплатен",
        standartTitle: "Стандартен",
        premiumTitle: "Премиум",
        specialTitle: "Специален",
        chooseBox: "Избери TV бокс"
    }, _define_property(_obj, "tvBox", "Tv бокс"), _define_property(_obj, "specifications", "Спецификации"), _define_property(_obj, "previewText", "Детайли за поръчката"), _define_property(_obj, "priceHint", "* Цена за по-малък период."), _define_property(_obj, "successPayment", "Плащането е успешно"), _define_property(_obj, "finalStepText", "Заплатихте"), _define_property(_obj, "discount", "{1}% отстъпка"), _define_property(_obj, "greeting", "Благодарим ви, че избрахте Elemental.TV!"), _define_property(_obj, "purchaseAgreement", 'С натискане на бутона "Плати" ще извършите плащане за споменатата горе сума.'), _define_property(_obj, "currentPaymentMethod", "Настоящo платежнo средство"), _define_property(_obj, "noPaymentMethod", "Няма въведен платежен метод"), _define_property(_obj, "areYouSureChangeCountry", "Локацията оказва влияние върху вида и цената на услугата.<br/>Сигурни ли сте, че искате да продължите?"), _obj),
    subscription: {
        currentSubscription: "Текущ абонамент",
        change: "Промени",
        plan: "План",
        indefinite: "Безсрочен",
        additionalPackages: "Допълнителни пакети",
        notGoingToRenewAndWillBeTerminatedOn: "Няма да се поднови автоматично и ще бъде прекратен на",
        willBeTerminatedWithSubscriptionExpiration: "Ще бъде прекратен с изтичане на абонаментния план",
        expiringSubscriptionFull: "Вашият абонамент изтича на <b>{1}</b> и няма да бъде подновен автоматично. В случай че искате да гледате включеното съдържание и след тази дата:",
        expiringSubscription: "Вашият абонамент изтича на <b>{1}</b> и няма да бъде подновен автоматично.",
        expiringSubscriptionTitle: "Изтичащ абонамент",
        withDisabledRenewal: "с изключено автоматично подновяване",
        addons: "Допълнителни пакети",
        youCanRenewHere: "Може да подновите абонамента си като натиснете",
        orYouCanSubscribeHere: "или да изберете по-удобна за Вас услуга",
        here: "тук",
        fromHere: "оттук",
        youCanRenewFromButtonBelow: "Може да подновите абонамента си чрез бутона отдолу или да изберете по-удобна за Вас услуга",
        goBack: "Назад"
    },
    errors: {
        generic: "Възникна грешка",
        error200049: "Временно блокиран достъп",
        error200049detail: "Системата автоматично е ограничила достъпа ви след превишен брой на грешните опити за вход.",
        error200031: "Териториално ограничение!",
        error200031detail: "Услугата не е достъпна на територията, в която се намирате",
        error200053: "Териториално ограничение!",
        error200053detail: "Услугата не е достъпна на територията, в която се намирате",
        limitModalTitle: "ПРЕВИШЕН ЛИМИТ",
        limitModalDesc1: "Стартирали сте нов видеопоток в настоящия уеб браузър. Не е позволено едновременното гледане на повече от една телевизионна програма през един уеб браузър.",
        limitModalDesc2: "Ако в момента гледате само на един прозорец, препоръчваме да смените паролата си.",
        limitModalStop: "Спри видеото",
        limitModalContinue: "Продължи да гледаш",
        noSelectedPlan: "Не сте избрали абонаментен план",
        noSelectedAddons: "Не сте избрали допълнителни пакети",
        noSelectedPaymentMethod: "Не сте избрали платежен метод",
        noAddress: "Не сте въвели адрес",
        invalidPhoneNumber: "Невалиден телефонен номер",
        noMessage: "Не сте въвели съобщение",
        noPassword: "Не сте въвели парола",
        noEmail: "Не сте въвели имейл",
        prismic: {
            NO_MASTER_REF: "Грешка при зареждане на съдържание: Не е намерена мастър референция",
            NO_RESPONSE: "Грешка при зареждане на съдържание: Няма отговор от сървъра"
        },
        latinCharacters: "Моля, попълнете с латински букви",
        cyrillicCharacters: "Моля, попълнете на кирилица",
        listOfVisiblePlansIsEmpty: 'В момента няма налични планове за избрания регион. Моля, свържете се с нас от секция "Помощ".'
    },
    authentication: {
        limitTimeRemaining: "Остават"
    },
    times: {
        hour: "часа",
        minutes: "мин.",
        seconds: "сек."
    },
    vod: "Видеотека",
    delete: {
        deleteAccount: "Изтриване на акаунт",
        contactPhoneNumber: "Телефон за вързка",
        whyWeNeedYourPhoneNumber: "Телефонния Ви номер ни е необходим да се свържем с Вас и да уточним всички детайли по изтриването на Вашия акаунт. След завършване на процеса, той също ще бъде заличен.",
        pleaseTellUs: "Моля, кажете ни защо искате да изтриете акаунта си",
        enterCurrentPassword: "Въведете текущата си парола",
        delete: "Изтриване",
        cancel: "Отказ",
        pleaseFillOutForm: "Моля попълнете тази форма, за да заявите заличаване на Вашия акаунт в платформата на {1}. След изтриване няма да имате възможност да възстановите услугите, които все още са били активни към този момент.",
        accountDeletionRequest: "Заявка за изтриване на акаунт",
        request: "Заявка",
        requestSent: "Заявката е изпратена успешно",
        sorryToSeeYouGo: "Съжаляваме, че си тръгвате",
        yourRequestHasBeenSent: "Вашата заявка е подадена успешно. Служителите ни ще се свържат с Вас възможно най-скоро на посочения от Вас телефонен номер и ще създействат за окончателното изтриване на акаунта Ви.",
        enterEmail: "Въведете имейл"
    }
};
