import { MOST_WATCHED_GET_EPGS } from "@common/constants/action-types";
export default function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, _ref = arguments.length > 1 ? arguments[1] : void 0, payload = _ref.payload, type = _ref.type;
    switch(type){
        case MOST_WATCHED_GET_EPGS:
            return payload;
        default:
            return state;
    }
};
