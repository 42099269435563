import { VIDEO_RESET_AFTER_SUBSCRIPTION, VIDEO_SET_AFTER_SUBSCRIPTION } from "@common/constants/action-types";
export var setVideoToPlayAfterSubscription = function(video) {
    return function(dispatch) {
        dispatch({
            type: VIDEO_SET_AFTER_SUBSCRIPTION,
            payload: video
        });
    };
};
export var resetVideoToPlayAfterSubscription = function() {
    return function(dispatch) {
        dispatch({
            type: VIDEO_RESET_AFTER_SUBSCRIPTION,
            payload: {}
        });
    };
};
