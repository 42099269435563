export var BREAKPOINTS = {
    s: 612,
    m: 908,
    l: 1204,
    xl: 1500,
    xxl: 1796,
    lim: 100000
};
export var SCROLLBAR = {
    FAQ: {
        width: "103%",
        height: 700,
        thumbSize: 150
    },
    INVOICES: {
        width: "103%",
        height: 508,
        thumbSize: 150
    },
    GDPR: {
        width: "104%",
        thumbSize: 150
    }
};
export var SLIDER = "slider";
export var SLIDER_BIG = "sliderBig";
export var SLIDER_FOR_PLAYER = "sliderForPlayer";
