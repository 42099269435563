function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { SEARCH_CHANNEL_RESULT, SEARCH_CLEAR, SEARCH_FILTER_RESULT, SEARCH_SET_RESULT } from "@common/constants/action-types";
import { FILTER_TYPE_LIVE, FILTER_TYPE_RECORDED } from "@config/constants";
export default function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        hasSearch: false,
        filteredFuture: [],
        filteredRecorded: [],
        future: [],
        recorded: [],
        representedCategories: new Set(),
        representedFutureCategories: new Set(),
        representedRecordedCategories: new Set()
    }, _ref = arguments.length > 1 ? arguments[1] : void 0, payload = _ref.payload, type = _ref.type;
    switch(type){
        case SEARCH_SET_RESULT:
            return _object_spread_props(_object_spread({}, state), {
                hasSearch: true,
                filteredFuture: _to_consumable_array(payload.future),
                filteredRecorded: _to_consumable_array(payload.recorded),
                future: _to_consumable_array(payload.future),
                recorded: _to_consumable_array(payload.recorded),
                filteredLive: state.filteredLive,
                representedCategories: payload.representedCategories,
                representedFutureCategories: payload.representedFutureCategories,
                representedRecordedCategories: payload.representedRecordedCategories
            });
        case SEARCH_CLEAR:
            return _object_spread_props(_object_spread({}, state), {
                hasSearch: payload.shouldResetLiveFilter ? false : true,
                filteredFuture: [],
                filterType: payload.shouldResetLiveFilter ? "" : FILTER_TYPE_LIVE,
                category: payload.shouldResetLiveFilter ? null : state.filterLivePreviousCategory,
                filteredRecorded: [],
                future: [],
                filteredLive: payload.shouldResetLiveFilter ? [] : state.filteredLive,
                recorded: [],
                representedCategories: new Set(),
                representedFutureCategories: new Set(),
                representedRecordedCategories: new Set()
            });
        case SEARCH_FILTER_RESULT:
            return _object_spread_props(_object_spread({}, state), {
                filteredLive: payload.filterType === FILTER_TYPE_RECORDED ? state.filteredLive : payload.filteredLive,
                filterLivePreviousCategory: payload.filterType === FILTER_TYPE_LIVE ? payload.category : state.category,
                filteredFuture: payload.filteredFuture,
                filteredRecorded: payload.filteredRecorded,
                filterType: payload.filterType,
                category: payload.category
            });
        case SEARCH_CHANNEL_RESULT:
            return _object_spread_props(_object_spread({}, state), {
                filteredSearchLive: payload.filteredSearchLive || state.filteredSearchLive
            });
        default:
            return state;
    }
};
