import { combineReducers } from "redux";
import appConfig from "./appConfig";
import authentication from "./authentication";
import currentChannel from "./currentChannel";
import errors from "./errors";
import filteredLiveChannels from "./filteredLiveChannels";
import geolocCountryCode from "./geolocCountryCode";
import header from "./header";
import i18n from "./i18n";
import lastWatched from "./lastWatched";
import liveCategories from "./liveCategories";
import liveChannels from "./liveChannels";
import logos from "./logos";
import modal from "./modal";
import mostWatched from "./mostWatched";
import order from "./order";
import prismic from "./prismic";
import profileSettings from "./profileSettings";
import recorded from "./recorded";
import screenType from "./screenType";
import searchState from "./searchState";
import serverTime from "./serverTime";
import settingsChannelsConfig from "./settingsChannelsConfig";
import settingsResolutionsConfig from "./settingsResolutionsConfig";
import userDetails from "./userDetails";
import videoAfterSubscription from "./videoAfterSubscription";
import videoPlayer from "./videoPlayer";
var rootReducer = combineReducers({
    appConfig: appConfig,
    // settingsSubsConfig,
    // settingsAudioConfig,
    authentication: authentication,
    currentChannel: currentChannel,
    errors: errors,
    header: header,
    i18n: i18n,
    lastWatched: lastWatched,
    liveCategories: liveCategories,
    liveChannels: liveChannels,
    filteredLiveChannels: filteredLiveChannels,
    logos: logos,
    modal: modal,
    mostWatched: mostWatched,
    order: order,
    profileSettings: profileSettings,
    recorded: recorded,
    screenType: screenType,
    searchState: searchState,
    serverTime: serverTime,
    videoAfterSubscription: videoAfterSubscription,
    userDetails: userDetails,
    videoPlayer: videoPlayer,
    settingsResolutionsConfig: settingsResolutionsConfig,
    settingsChannelsConfig: settingsChannelsConfig,
    prismic: prismic,
    geolocCountryCode: geolocCountryCode
});
export default rootReducer;
