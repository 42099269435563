import { LIVE_CHANNELS_GET } from "@common/constants/action-types";
export default function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case LIVE_CHANNELS_GET:
            return action.payload;
        default:
            return state;
    }
}
