import { commonTranslations } from "@common/config/translations";
import { TRANSLATIONS_GET_COMMON } from "@common/constants/action-types";
import { deepMergeFlatten } from "@common/utils/helpers";
import { merge } from "lodash";
export default function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case TRANSLATIONS_GET_COMMON:
            return merge(commonTranslations, action.payload);
        default:
            return state;
    }
}
