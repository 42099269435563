function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { VIDEO_ALL_EPG, VIDEO_CURRENT, VIDEO_OPTIONS, VIDEO_PLAYER_EPGS, VIDEO_PLAYER_TOGGLE, VIDEO_RESET } from "@common/constants/action-types";
import { VOLUME_MAX } from "@config/constants";
var videoInitialState = {
    source: null,
    start: null,
    stop: null,
    duration: 0,
    currentTime: 0,
    seekingTime: 0,
    paused: true,
    ended: false,
    playbackRate: 1,
    muted: false,
    volume: VOLUME_MAX,
    lastPositiveVolume: VOLUME_MAX,
    readyState: 0,
    networkState: 0,
    hasStarted: false,
    isFullscreen: false,
    currentLevel: null,
    levels: null,
    isVod: false
};
export default function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        isOpen: false,
        isPlaying: false,
        allEpgs: null,
        video: videoInitialState,
        epgs: null
    }, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case VIDEO_CURRENT:
            return _object_spread_props(_object_spread({}, state, action.payload), {
                video: _object_spread({}, state.video, action.payload.video)
            });
        case VIDEO_PLAYER_TOGGLE:
            return _object_spread_props(_object_spread({}, state), {
                isOpen: action.payload.isOpen,
                isPlaying: action.payload.isPlaying
            });
        case VIDEO_ALL_EPG:
            return _object_spread({}, state, action.payload);
        case VIDEO_RESET:
            return {
                isOpen: false,
                isPlaying: false,
                allEpgs: null,
                video: {},
                epgs: null
            };
        case VIDEO_OPTIONS:
            return _object_spread_props(_object_spread({}, state), {
                video: _object_spread({}, state.video, action.payload)
            });
        case VIDEO_PLAYER_EPGS:
            return _object_spread_props(_object_spread({}, state), {
                epgs: action.payload
            });
        default:
            return state;
    }
}
