function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import { SEARCH_CHANNEL_RESULT, SEARCH_CLEAR, SEARCH_FILTER_RESULT, SEARCH_SET_RESULT } from "@common/constants/action-types";
import { secondsToMilliseconds } from "@common/services/helpers";
import request from "@common/services/request";
import { checkLockStatusForChannel, checkVisibleStatusForChannel } from "@common/utils/helpers";
import { CATEGORIES_ALL_ID, FILTER_TYPE_LIVE, FILTER_TYPE_RECORDED } from "@config/constants";
export var search = function(url, type) {
    var ajax = request({
        url: url,
        method: "get"
    });
    return function() {
        var _ref = _async_to_generator(function(dispatch, getState) {
            var data, representedCategories, representedFutureCategories, representedRecordedCategories, now, recorded, future, profileSettings, filteredChannels, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            ajax
                        ];
                    case 1:
                        data = _state.sent().data;
                        representedCategories = new Set();
                        representedFutureCategories = new Set();
                        representedRecordedCategories = new Set();
                        if (data.length > 0) {
                            // Separate the recorded from live/future shows
                            // because live/future are displayed only
                            // if the search is of type=active.
                            now = new Date().getTime();
                            recorded = [];
                            future = [];
                            profileSettings = getState().profileSettings;
                            // Filter out hidden channels
                            filteredChannels = data.filter(function(channel) {
                                return checkVisibleStatusForChannel(profileSettings, channel.chanId) && !checkLockStatusForChannel(profileSettings, channel.chanId);
                            });
                            filteredChannels.forEach(function(epg) {
                                var startMilliseconds = secondsToMilliseconds(epg.start);
                                var stopMilliseconds = secondsToMilliseconds(epg.stop);
                                representedCategories.add(epg.majorId);
                                if (startMilliseconds > now || stopMilliseconds > now) {
                                    future.push(epg);
                                    representedFutureCategories.add(epg.majorId);
                                } else {
                                    recorded.push(epg);
                                    representedRecordedCategories.add(epg.majorId);
                                }
                            });
                            // Make sure that live shows come first.
                            future.sort(function(param, param1) {
                                var startA = param.start, startB = param1.start;
                                return startA - startB;
                            });
                            dispatch({
                                type: SEARCH_SET_RESULT,
                                payload: {
                                    recorded: recorded,
                                    representedCategories: representedCategories,
                                    representedFutureCategories: representedFutureCategories,
                                    representedRecordedCategories: representedRecordedCategories,
                                    future: type ? future : []
                                }
                            });
                        } else {
                            dispatch({
                                type: SEARCH_CLEAR,
                                payload: {
                                    hasSearch: true
                                }
                            });
                        }
                        return [
                            3,
                            3
                        ];
                    case 2:
                        error = _state.sent();
                        console.error(error);
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch, getState) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var clearSearch = function(shouldResetLiveFilter) {
    return function(dispatch) {
        dispatch({
            type: SEARCH_CLEAR,
            payload: {
                hasSearch: false,
                shouldResetLiveFilter: shouldResetLiveFilter
            }
        });
    };
};
export var filterByChannelName = function(searchString) {
    return function(dispatch, getState) {
        var filteredSearchLive = [];
        var filteredLiveChannels = getState().filteredLiveChannels;
        if (searchString && searchString.trim().length >= 0) {
            Object.keys(filteredLiveChannels).forEach(function(id) {
                var channelName = filteredLiveChannels[id].name.toLowerCase();
                var trimmedSearchString = searchString.trim().toLowerCase();
                if (channelName.includes(trimmedSearchString)) {
                    filteredSearchLive.push(filteredLiveChannels[id]);
                }
            });
        } else {
            filteredSearchLive = filteredLiveChannels;
        }
        dispatch({
            type: SEARCH_CHANNEL_RESULT,
            payload: {
                filteredSearchLive: filteredSearchLive
            }
        });
    };
};
export var filterByCategory = function(category, filterType) {
    return function(dispatch, getState) {
        var filteredFuture = [];
        var filteredRecorded = [];
        var filteredLive = [];
        var future = getState().searchState.future;
        var recorded = getState().searchState.recorded;
        var filteredLiveChannels = getState().filteredLiveChannels;
        if (category !== CATEGORIES_ALL_ID && category !== null) {
            if (filterType === FILTER_TYPE_RECORDED) {
                filteredFuture = future.filter(function(param) {
                    var majorId = param.majorId;
                    return majorId === category;
                });
                filteredRecorded = recorded.filter(function(param) {
                    var majorId = param.majorId;
                    return majorId === category;
                });
            }
            if (filterType === FILTER_TYPE_LIVE) {
                var channels = getState().searchState.filteredSearchLive || filteredLiveChannels;
                Object.keys(channels).forEach(function(id) {
                    if (category === channels[id].category) {
                        filteredLive.push(channels[id]);
                    }
                });
            }
        } else {
            filteredFuture = _to_consumable_array(future);
            filteredRecorded = _to_consumable_array(recorded);
            filteredLive = filteredLiveChannels;
            category = CATEGORIES_ALL_ID;
        }
        dispatch({
            type: SEARCH_FILTER_RESULT,
            payload: {
                filteredFuture: filteredFuture,
                filteredRecorded: filteredRecorded,
                filteredLive: filteredLive,
                filterType: filterType,
                category: category
            }
        });
    };
};
