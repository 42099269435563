function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { CATEGORIES_GET, RECORDED_CLEAR_CATEGORIES_EPGS, RECORDED_CLEAR_CATEGORY_LIST, RECORDED_CLEAR_SUBCATEGORIES_EPGS, RECORDED_CLEAR_SUBCATEGORY_LIST, RECORDED_GET_CATEGORY_EPGS, RECORDED_GET_CATEGORY_LIST_NEXT_PAGE, RECORDED_GET_SUBCATEGORY_EPGS, RECORDED_GET_SUBCATEGORY_LIST_NEXT_PAGE } from "@common/constants/action-types";
export default function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        categories: {},
        subcategoriesEpgs: {},
        categoriesEpgs: {},
        categoryList: {},
        subcategoryList: {}
    }, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case CATEGORIES_GET:
            return _object_spread_props(_object_spread({}, state), {
                categories: action.payload
            });
        case RECORDED_GET_CATEGORY_EPGS:
            return _object_spread_props(_object_spread({}, state), {
                categoriesEpgs: _object_spread({}, state.categoriesEpgs, action.payload)
            });
        case RECORDED_CLEAR_CATEGORIES_EPGS:
            return _object_spread_props(_object_spread({}, state), {
                categoriesEpgs: {}
            });
        case RECORDED_GET_SUBCATEGORY_EPGS:
            return _object_spread_props(_object_spread({}, state), {
                subcategoriesEpgs: _object_spread({}, state.subcategoriesEpgs, action.payload)
            });
        case RECORDED_CLEAR_SUBCATEGORIES_EPGS:
            return _object_spread_props(_object_spread({}, state), {
                subcategoriesEpgs: {}
            });
        case RECORDED_GET_CATEGORY_LIST_NEXT_PAGE:
            var _action_payload = action.payload, data = _action_payload.data, id = _action_payload.id;
            var prevCatData = state.categoryList[id] || [];
            var newCatData = data[id];
            var updatedCategory = _define_property({}, id, newCatData.length > 0 ? _to_consumable_array(prevCatData).concat(_to_consumable_array(newCatData)) : _to_consumable_array(prevCatData));
            return _object_spread_props(_object_spread({}, state), {
                categoryList: _object_spread({}, state.categoryList, updatedCategory)
            });
        case RECORDED_CLEAR_CATEGORY_LIST:
            return _object_spread_props(_object_spread({}, state), {
                categoryList: _object_spread({}, state.categoryList, _define_property({}, action.payload.id, []))
            });
        case RECORDED_GET_SUBCATEGORY_LIST_NEXT_PAGE:
            var prevSubCatData = state.subcategoryList[action.payload.id] || [];
            var newSubCatData = action.payload.data[action.payload.id];
            var updatedSubcategory = _define_property({}, action.payload.id, newSubCatData.length > 0 ? _to_consumable_array(prevSubCatData).concat(_to_consumable_array(newSubCatData)) : _to_consumable_array(prevSubCatData));
            return _object_spread_props(_object_spread({}, state), {
                subcategoryList: _object_spread({}, state.subcategoryList, updatedSubcategory)
            });
        case RECORDED_CLEAR_SUBCATEGORY_LIST:
            return _object_spread_props(_object_spread({}, state), {
                subcategoryList: _object_spread({}, state.subcategoryList, _define_property({}, action.payload.id, []))
            });
        default:
            return state;
    }
}
